<template>
	<main class="content">
		<h1>Grading: {{ group.name }}</h1>

		<div class="block-area-wrapper">
			<section class="block-area">
				<article v-for="(block, i) in blocks" :key="block.id" class="block question">
					<GradingBlock :block="block" :index="i + 1" :answer="blockAnswer(block)" @update="updateScore" />
					<div class="block-type">
						{{ blockTypeLabel(block) }}
					</div>
				</article>
			</section>
		</div>

		<div class="content-footer">
			<BrandLogo />

			<nav>
				<BaseButton type="secondary" @click="goBack">
					<span>Back</span>
				</BaseButton>
				<BaseButton v-if="isLocked" type="primary" @click="unlock">
					<span>Unlock</span>
				</BaseButton>
				<BaseButton v-else type="primary" @click="saveGrade">
					<span>Save grade</span>
				</BaseButton>
			</nav>
		</div>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import {fetchGroupBlocks} from '../../inc/courseUtils';
	import Store from '../../inc/store';
	import GradingBlock from '../../components/GradingBlock';
	import blockDefs from '../../inc/blockDefinitions';
	import BrandLogo from '../../components/BrandLogo';
	import BaseButton from '../../components/BaseButton';

	export default {
		name: 'GradeSubmissions',
		components: {
			GradingBlock,
			BrandLogo,
			BaseButton
		},
		props: {
			sessionId: {
				type: String,
				required: true
			},
			groupId: {
				type: String,
				required: true
			},
			courseId: {
				type: String,
				required: true
			}
		},
		computed: {
			blocks() {
				if(!Store.groupBlocks) {
					return [];
				}

				return Store.groupBlocks.filter(block => !!block.meta.maxScore);
			},
			group() {
				return Store.courseGroup;
			},
			answers() {
				return Store.groupAnswers;
			},
			progress() {
				return Store.courseGroupProgress;
			},
			isLocked() {
				return !!this.progress.timeGraded;
			}
		},
		created() {
			this.fetchAnswers();

			Backend.get(`courses/${this.courseId}/groups/${this.groupId}`).then(res => {
				Store.courseGroup = res.data;
			});

			fetchGroupBlocks(this.groupId, this.courseId);
		},
		methods: {
			blockAnswer(block) {
				const answer = this.answers.find(a => a.blockId === block.id);

				if(!answer) {
					return {};
				}

				return answer;
			},
			blockTypeLabel(block) {
				return blockDefs.find(def => def.type === block.type).label;
			},
			updateScore(score, answerId, blockId) {
				Backend.put(`sessions/${this.sessionId}/answers/${blockId}/grade`, {score});
			},
			saveGrade() {
				Backend.put(`sessions/${this.sessionId}/groups/${this.groupId}/grade`)
					.then(res => this.$set(Store.courseGroupProgress, 'timeGraded', res.data.timeGraded))
					.then(this.fetchAnswers);
			},
			fetchAnswers() {
				Backend.get(`sessions/${this.sessionId}/groups/${this.groupId}/progress`).then(res => {
					Store.groupAnswers = res.data.answers;
					Store.courseGroupProgress = res.data.progress;
				});
			},
			unlock() {
				Backend.delete(`sessions/${this.sessionId}/groups/${this.groupId}/grade`)
					.then(() => this.$set(Store.courseGroupProgress, 'timeGraded', null));
			},
			goBack() {
				this.$router.push({name: 'AdminSubmissionGroups', query: {sessionId: this.sessionId}});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.block-area-wrapper::v-deep {
		display: flex;
		flex-flow: column nowrap;
		flex: 1;
		padding: 0 !important;
	}

	.block-area {
		counter-reset: question;
	}

	.block {
		position: relative;
		margin-bottom: 1rem;

		&:not(:first-child) {
			border-top: 1px solid $border_color;
		}
	}

	.block-type {
		position: absolute;
		bottom: 5px;
		right: 10px;
		text-align: center;
		text-transform: uppercase;
		font-size: 0.75em;
		opacity: 0.5;
		cursor: inherit;
	}

	.content-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		border-top: $border;
		padding: $site__padding $site__padding * 2 0;

		.logo-wrapper {
			padding: 0;
		}
	}
</style>