<template>
	<div class="block-inner">
		<div class="answers-wrapper">
			<p class="block-text" :data-question="index" v-html="blockText" />

			<div v-if="questionChanged" class="block-change-notice">
				<strong>Question changed!</strong><br />
				<span>This question has changed since the answer was submitted. The question the student answered was:</span>
				<div style="margin-top: 1em;" v-html="oldQuestion" />
			</div>

			<p class="submitted">
				<strong>Submitted answer:</strong><br />
				<span v-html="userAnswer"></span>
			</p>

			<p v-if="correctAnswer" class="correct">
				<strong>Correct answer:</strong><br />
				<span v-html="correctAnswer"></span>
			</p>

			<div v-if="optionsChanged" class="block-change-notice">
				<strong>Options changed!</strong><br />
				<span>The answer options for this question may have changed since the answer was submitted.</span>
			</div>
		</div>

		<div class="grade">
			<input v-if="isLocked" type="number" :value="displayAnswer.score" disabled />
			<input v-else v-model="displayAnswer.score" type="number" :min="0" :max="displayAnswer.maxScore" @input="$emit('update', displayAnswer.score, displayAnswer.id, block.id)" />
			<span>/ {{ displayAnswer.maxScore }}</span>
		</div>
	</div>
</template>

<script>
	import {jsonToHtml} from '../inc/text';
	import ObjectStore from '../inc/objectStore';
	import Store from '../inc/store';
	import {getUserAnswer, getCorrectAnswer} from '../inc/grading';

	export default {
		props: {
			block: {
				type: Object,
				required: true
			},
			answer: {
				type: Object,
				required: true
			},
			index: {
				type: Number,
				required: true
			}
		},
		data() {
			return {
				files: []
			};
		},
		computed: {
			questionChanged() {
				return this.answer.blockChanged && JSON.stringify(this.block.content) !== JSON.stringify(this.answer.question);
			},
			optionsChanged() {
				return this.answer.blockChanged && this.block.options;
			},
			displayAnswer() {
				if(Object.keys(this.answer).length) {
					return this.answer;
				}

				return {
					score: 0,
					maxScore: this.block.meta.maxScore,
					id: false,
					answers: []
				};
			},
			blockText() {
				return '<strong>Question:</strong> ' + jsonToHtml(this.block.content);
			},
			oldQuestion() {
				return jsonToHtml(this.answer.question);
			},
			isLocked() {
				return !!Store.courseGroupProgress.timeGraded;
			},
			answers() {
				if(!this.answer || !this.answer.answers) {
					return false;
				}

				if(Array.isArray(this.answer.answers) && !this.answer.answers.length) {
					return false;
				}

				if(typeof this.answer.answers === 'object' && !Object.keys(this.answer.answers).length) {
					return false;
				}

				return this.answer.answers;
			},
			userAnswer() {
				return getUserAnswer(this.answers, this.block, this.files);
			},
			correctAnswer() {
				return getCorrectAnswer(this.block, this.files);
			}
		},
		watch: {
			answer() {
				if(this.answers && this.answers.files) {
					for(const file of this.answers.files) {
						ObjectStore.getFileUrl(file).then(url => {
							this.files.push({
								name: file.name,
								url
							});
						});
					}
				}
				else if(this.block.type === 'pair' && this.answers.length) {
					for(const answers of this.answers) {
						for(const answer of answers) {
							const lhsItem = this.block.items.lhs.find(i => i.id === answer[0] && i.type === 'image');
							const rhsItem = this.block.items.rhs.find(i => i.id === answer[1] && i.type === 'image');

							if(lhsItem) {
								ObjectStore.getFileUrl(lhsItem.file).then(url => {
									this.files.push({
										id: lhsItem.id,
										name: lhsItem.file.name,
										url
									});
								});
							}

							if(rhsItem) {
								ObjectStore.getFileUrl(rhsItem.file).then(url => {
									this.files.push({
										id: rhsItem.id,
										name: rhsItem.file.name,
										url
									});
								});
							}
						}
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.block-inner {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		padding: $site__padding $site__padding * 2;
	}

	.answers-wrapper::v-deep {
		flex: 1;

		ol {
			list-style-type: decimal;
		}

		ul ul {
			list-style-type: initial;
		}

		video {
			max-width: 640px;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	.block-text::v-deep {
		position: relative;

		@include question-counter();

		.options::before {
			content: '[options]';
		}
	}

	.grade {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		input {
			flex: 0 0 80px;
			margin-right: 10px;
			margin-bottom: 0;
			font-size: 1.25rem;

			&[disabled] {
				background-color: $color__semi_dark;
			}
		}

		span {
			white-space: nowrap;
			flex: 1;
			font-size: 1.25rem;
		}
	}

	.block-change-notice {
		max-width: 100%;
		width: 800px;
		background: rgba($color__red, 0.05);
		padding: 10px;
		border-radius: 4px;
		border: $border;
		margin-bottom: 1.35em;
	}
</style>