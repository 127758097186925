/* eslint-disable require-jsdoc */
import {jsonToHtml} from './text';

/**
 * Get the formatted user submitted answer for the provided block.
 * @param {Array} answers The answers submitted for this block
 * @param {Object} block The current question block
 * @param {Array} files Files associated with these answers
 * @returns {string} The formatted answer
 */
export function getUserAnswer(answers, block, files) {
	if(!answers) {
		return '';
	}

	if(typeof answers === 'string') {
		return answers;
	}

	switch (block.type) {
	case 'boolean':
		return getUserAnswerBoolean(answers);
	case 'options':
		return getUserAnswerOptions(answers);
	case 'opencloze':
		return getUserAnswerOpenClose(answers);
	case 'recaudio':
		return getUserAnswerRecAudio(files);
	case 'recvideo':
		return getUserAnswerRecVideo(files);
	case 'files':
		return getUserAnswerFiles(files);
	case 'pair':
		return getUserAnswerPair(answers, block, files);
	case 'sort':
		return getUserAnswerSort(answers, block);
	case 'group':
		return getUserAnswerGroup(answers, block);
	default:
		return 'N/A';
	}
}

/**
 * The formatted correct answers for the provided block
 * @param {Object} block The current question block
 * @param {Array} files Files associated with these answers
 * @returns {string} The formatted correct answers
 */
export function getCorrectAnswer(block, files) {
	switch (block.type) {
	case 'boolean':
	case 'options':
		return getCorrectOptionLabel(block, Object.keys(block.options).pop());
	case 'opencloze':
		return getCorrectAnswerOpenClose(block);
	case 'sort':
		return getCorrectAnswerSort(block);
	case 'group':
		return getCorrectAnswerGroup(block);
	case 'pair':
		return getCorrectAnswerPair(block, files);
	default:
		return '';
	}
}

function getUserAnswerBoolean(answers) {
	return answers[0].options[0].label;
}

function getUserAnswerOptions(answers) {
	let label = '<ol>';

	for(const op of answers[0].options) {
		label += `<li>${op.label}</li>`;
	}

	label += '</ol>';

	return label;
}

function getUserAnswerOpenClose(answers) {
	let label = '<ol>';

	for(const a of answers) {
		if(a.value && a.value.length) {
			label += `<li>${a.value}</li>`;
		}
		else if(a.options && a.options.length) {
			label += `<li>${a.options[0].label}</li>`;
		}
	}

	label += '</ol>';

	return label;
}

function getUserAnswerRecAudio(files) {
	return files && files[0] ? `<audio src="${files[0].url}" controls>` : '';
}

function getUserAnswerRecVideo(files) {
	return files && files[0] ? `<video src="${files[0].url}" controls>` : '';
}

function getUserAnswerFiles(files) {
	let label = '';

	if(files && files.length) {
		for(const file of files) {
			label += `<a href="${file.url}" target="_blank" rel="noopener noreferrer">${file.name}</a><br />`;
		}
	}

	return label;
}

function getUserAnswerPair(allAnswers, block, files) {
	let label = '';

	if(allAnswers && allAnswers.length) {
		label = '<table>';

		for(const answers of allAnswers) {
			for(const answer of answers) {
				const lhsItem = block.items.lhs.find(i => i.id === answer[0]);
				const rhsItem = block.items.rhs.find(i => i.id === answer[1]);

				label += '<tr><td>' + getItemLabel(lhsItem, files) + '</td><td>' + getItemLabel(rhsItem, files) + '</td></tr>';
			}
		}

		label += '</table>';
	}

	return label;
}

function getUserAnswerSort(allAnswers, block) {
	let label = '';

	if(allAnswers && allAnswers.length) {
		label = '<ol>';

		for(const answers of allAnswers) {
			for(const answer of answers) {
				const item = block.items.find(i => i.id === answer);

				label += '<li>' + item.label + '</li>';
			}
		}

		label += '</ol>';
	}

	return label;
}

function getUserAnswerGroup(allAnswers, block) {
	let label = '';

	const answers = allAnswers && allAnswers.length ? allAnswers[0] : [];

	if(block.targets) {
		label = '<ul>';

		for(const target of block.targets) {
			label += '<li>' + target.label + '</li>';
			label += '<ul>';

			const answer = answers.find(a => a.id === target.id);
			const items = target.items.filter(i => answer.items.includes(i.id));

			for(const item of items) {
				label += '<li>' + item.label + '</li>';
			}

			label += '</ul>';
		}

		label += '</ul>';
	}

	return label;
}

function getCorrectAnswerOpenClose(block) {
	let label = '<ol>';

	for(const id of getDOMOptionGroups(block)) {
		label += '<li>' + getCorrectOptionLabel(block, id) + '</li>';
	}

	label += '</ol>';

	return label;
}

function getCorrectAnswerSort(block) {
	let label = '';

	if(block.items) {
		label = '<ol>';

		for(const item of block.items) {
			label += '<li>' + item.label + '</li>';
		}

		label += '</ol>';
	}

	return label;
}

function getCorrectAnswerGroup(block) {
	let label = '';

	if(block.targets) {
		label = '<ul>';

		for(const target of block.targets) {
			label += '<li>' + target.label + '</li>';
			label += '<ul>';

			for(const item of target.items) {
				label += '<li>' + item.label + '</li>';
			}

			label += '</ul>';
		}

		label += '</ul>';
	}

	return label;
}

function getCorrectAnswerPair(block, files) {
	let label = '';

	if(block.items) {
		label = '<table>';

		for(const index in block.items.lhs) {
			label += '<tr><td>' + getItemLabel(block.items.lhs[index], files) + '</td><td>' + getItemLabel(block.items.rhs[index], files) + '</td></tr>';
		}

		label += '</table>';
	}

	return label;
}

function getItemLabel(item, files) {
	if(item.type === 'image' && files && files.length) {
		const file = files.find(f => f.id === item.id);

		if(file) {
			return `<a href="${file.url}" target="_blank" rel="noopener noreferrer">${file.name}</a><br />`;
		}
	}

	return item.label;
}

function getCorrectOptionLabel(block, optionGroup) {
	if(block.options && block.options[optionGroup]) {
		const correctOptions = block.options[optionGroup].filter(op => op.isCorrect).map(op => op.label);

		return correctOptions.join(', ') || 'N/A';
	}

	return '';
}

function getDOMOptionGroups(block) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(jsonToHtml(block.content), 'text/html');
	const groups = doc.querySelectorAll('.options');

	if(groups) {
		return Array.from(groups).map(el => el.dataset.id);
	}

	return [];
}